import { fetchingContainerDefaultState } from '_helpers/datacontainer/datacontainer';
import { appConfig } from 'config';
import { Md5 } from 'ts-md5';
import { IAuthRes } from 'types/auth';
import localStorageHelper from '_helpers/localStorage';
import * as actions from '../actions';
import { InferValueTypes } from '../../types/store';

type ActionTypes = ReturnType<InferValueTypes<typeof actions>>;

const defaultState = fetchingContainerDefaultState(false);

const getSiriusBaseUrl = () => {
  // Staging
  if (/staging/i.test(window.location.origin)) {
    return 'https://shop-staging.paxport.se/';
  }

  // Prod
  if (/secure/i.test(window.location.origin)) {
    return 'https://shop.paxport.net/';
  }

  // Default test
  // if (/test/i.test(window.location.origin)) {
  return 'https://shop-test.paxport.se/main/';
  // For dev:
  // return 'http://localhost:5173/';
};

const generateSiriusLink = (
  authRes: Pick<IAuthRes, 'bookingNumber' | 'departureDate' | 'lastName'>
) => {
  const url = new URL(getSiriusBaseUrl() + appConfig.siriusBasePath);
  // TODO lang?
  // Include referenceData?
  (['bookingNumber', 'departureDate', 'lastName'] as const).forEach(k => {
    if (authRes[k]) {
      url.searchParams.append(k, authRes[k]);
    }
  });
  return url.toString();
};

/**
 * Returns true for a some bookingNumbers.
 * The fraction of true depends on the appConfig.redirectToSirius-config
 * Safe to increase the fraction of bookingNumbers.
 *
 * @param {string} bookingNumber
 * @returns {boolean}
 */
function redirectToSirius(bookingNumber: string): boolean {
  // SQL equivalent: SELECT ('0x'||RIGHT(MD5(UPPER(TRIM(booking_number))), 2))::float::numeric / 255 < redirectToSirius AS UseSirius
  return (
    parseInt(Md5.hashStr(bookingNumber.trim().toUpperCase()).slice(-2), 16) /
      255 <
    appConfig.redirectToSirius
  );
}

export default function authSiriusRedirect(
  state = defaultState,
  action: ActionTypes
) {
  if (action.type === 'PAXSHOP_POST_LOGIN') {
    console.log('authSiriusRedirect', action.authRes.bookingNumber);
    if (
      ((appConfig.redirectToSirius && !appConfig.topShopRedirect) ||
        (appConfig.redirectToSirius &&
          appConfig.topShopRedirect &&
          appConfig.topShopCarrierRedirect.includes(
            action.authRes.carrierCustomerId
          ))) &&
      action.authRes.bookingNumber &&
      redirectToSirius(action.authRes.bookingNumber)
    ) {
      // TODO: Not working! If back-button is used, user is still logged in.
      localStorageHelper.resetAllButLanguage();
      window.location.href = generateSiriusLink(action.authRes);
    }
  }
  return state;
}
