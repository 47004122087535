import { IAuthSrcData } from 'types/auth';
import { IAuthResult } from 'types/backendProtocol';
import { PAXSHOP_LOGOUT_ACTION_ID } from './constants';

export function logout() {
  return {
    type: PAXSHOP_LOGOUT_ACTION_ID,
  } as const;
}

export function initiateLogin(authSrc: IAuthSrcData) {
  return {
    type: 'PAXSHOP_INITIATE_LOGIN',
    authSrc,
  } as const;
}

export function postLogin(
  authRes: Pick<
    IAuthResult,
    'bookingNumber' | 'departureDate' | 'lastName' | 'carrierCustomerId'
  >
) {
  return {
    type: 'PAXSHOP_POST_LOGIN',
    authRes,
  } as const;
}

export function updateAllInfantsHaveRef() {
  return {
    type: 'UPDATE_ALL_INFANTS_HAVE_REF',
  } as const;
}
